@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-image: repeating-linear-gradient(
      90deg,
      hsla(57, 0%, 42%, 0.09) 0px,
      hsla(57, 0%, 42%, 0.09) 1px,
      transparent 1px,
      transparent 60px
    ),
    repeating-linear-gradient(
      0deg,
      hsla(57, 0%, 42%, 0.09) 0px,
      hsla(57, 0%, 42%, 0.09) 1px,
      transparent 1px,
      transparent 60px
    ),
    repeating-linear-gradient(
      0deg,
      hsla(57, 0%, 42%, 0.09) 0px,
      hsla(57, 0%, 42%, 0.09) 1px,
      transparent 1px,
      transparent 10px
    ),
    repeating-linear-gradient(
      90deg,
      hsla(57, 0%, 42%, 0.09) 0px,
      hsla(57, 0%, 42%, 0.09) 1px,
      transparent 1px,
      transparent 10px
    ),
    linear-gradient(90deg, rgb(20, 20, 20), rgb(20, 20, 20));
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.main-nav {
  position: fixed;
  bottom: 0.5rem;
  left: 0;
  width: 100vw;
  height: 4rem;

  .social-links {
    display: flex;
    height: 100%;
    max-width: 500px;
    margin: 0 auto;
    list-style-type: none;
    justify-content: space-around;

    .social-link {
      a {
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
        width: 3rem;
        height: 3rem;
        padding: 0.5rem;
        transition: transform 200ms;

        &:hover,
        &:focus {
          transform: scale(1.2);
        }

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

#typewriter {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;

  .content {
    padding: 0 10vw;
  }
}

h1 {
  font-family: 'Share Tech Mono', monospace;
  color: #fff;
  position: relative;
  font-size: 8vw;
  overflow: hidden;
  display: inline-block;
  padding: 5px;

  .box {
    position: absolute;
    background-color: #646b64;
    height: 1vw;
    bottom: 0;
    display: inline-block;
    z-index: -1;
  }

  .greeting {
    display: inline-block;
    margin: 0 1vw;
  }
}
